<template>
  <div>
    <transition name="slidedown">
      <div v-if="showSearch" class="absolute top-0 left-0 w-full bg-f-bg bo:bg-f-white py-8 z-70">
        <x-mark-icon
          class="w-8 absolute top-2 right-2 text-f-white bo:text-f-tertiary cursor-pointer"
          @click="closeSearchBar" />
        <div class="w-full flex items-center justify-center mb-8">
          <logo />
        </div>
        <div class="bg-f-tertiary bo:bg-f-white p-6 rounded-xl border-2 border-f-bg/[.07] hj:border-0 mx-4">
          <SearchBar
            :activities="searchForm.activities"
            :venues="searchForm.venues"
            :reservation-id="reservationId"
            @search-finished="searchFinished" />
        </div>
      </div>
    </transition>

    <div class="hj:text-f-white">
      <div class="text-center">
        <div v-if="headingText || subHeadingText" class="-mb-4 mt-12">
          <h1 class="bo:font-graphik-bold hj:font-druk-heavy-italic hj:text-f-white text-3xl text-center mb-2">
            {{ headingText }}
          </h1>
          <p class="text-f-bg bo:text-f-tertiary hj:text-f-white text-center">{{ subHeadingText }}</p>
        </div>
        <SearchCriteria :activities="searchForm.activities" :venues="searchForm.venues" @click="showSearchBar" />
        <!--        <div v-if="selectedBookingSlot" class="flex flex-col items-center justify-center mt-10">-->
        <!--          <p class="text-f-bg bo:text-f-tertiary hj:text-f-white font-bold mb-2">Your selection</p>-->
        <!--          <SelectedSlot :activities="searchForm.activities" :venues="searchForm.venues" />-->
        <!--        </div>-->
      </div>

      <div v-if="loading">
        <CalendarSkeleton :hide-search-bar="true" />
      </div>

      <div v-if="!loading" class="w-full md:w-10/12 lg:max-w-screen max-w-6xl overflow-hidden md:px-0 mt-4 mx-auto">
        <p
          v-if="!searchResults.venue_open_at_searched_date_time && searchParams.when.value"
          class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-lg hj:text-3xl text-center mb-1">
          We are closed on
          {{ DateTime.fromFormat(searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("EEEE dd MMM") }}
        </p>
        <p
          class="bo:font-graphik-semibold md:hj:font-druk-heavy-italic hj:text-md md:text-lg md:hj:text-3xl text-center flex items-center justify-center mt-2 mb-6 md:mt-8 md:mb-5">
          Please select a time below <br />
          to continue your booking
        </p>
        <div v-if="Object.values(searchResults.activities).length > 1" class="mb-12 mx-2">
          <h2 class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-2xl text-f-bg hj:text-f-white mb-2">
            Activity
          </h2>
          <div
            class="flex justify-start items-center mb-7 p-4 border-2 border-f-bg/[.07] hj:border-f-tertiary hj:border-white/20 rounded-xl w-auto">
            <div
              v-if="!isMobile"
              v-for="(activity, i) in sortedResultActivities"
              :key="i"
              class="rounded-lg px-4 py-2.5 mr-2 hover:hj:text-f-white hover:bg-f-tertiary/[.07] hover:hj:bg-f-white/10 cursor-pointer"
              :class="{
                'text-f-bg bo:text-f-tertiary/50 hj:text-f-white/50 !cursor-not-allowed pointer-events-none':
                  !activityHasAvailability(activity),
                'bg-f-bg hj:bg-f-tertiary hover:text-f-bg bo:text-f-tertiary hover:hj:text-f-white !text-f-white font-bold':
                  activity.id === selectedActivity.id,
                'hj:text-f-white': activity.id !== selectedActivity.id,
              }"
              @click="changeActivity(activity)">
              {{ activity.name }}
            </div>
            <div v-if="isMobile" class="b-calendar activity flex flex-wrap">
              <!-- <vue-horizontal-es responsive ref="activities"> -->
              <div
                v-for="(activity, i) in sortedResultActivities"
                :key="i"
                class="rounded-lg px-4 py-2.5 mr-2 hover:hj:text-f-white hover:bg-f-tertiary/[.07] hover:hj:bg-f-white/10 cursor-pointer whitespace-nowrap"
                :class="{
                  'text-f-bg bo:text-f-tertiary/50 hj:text-f-white/50 !cursor-not-allowed pointer-events-none':
                    !activityHasAvailability(activity),
                  'bg-f-bg hj:bg-f-tertiary hover:text-f-bg bo:text-f-tertiary hover:hj:text-f-white text-f-white font-bold':
                    activity.id === selectedActivity.id,
                  'hj:text-f-white/50': activity.id !== selectedActivity.id,
                }"
                @click="changeActivity(activity)">
                {{ activity.name }}
              </div>
              <!-- </vue-horizontal-es> -->
            </div>
          </div>
        </div>
        <div>
          <div class="relative">
            <div v-if="!selectedActivityHasAvailability">
              <div class="flex justify-center items-center h-[82px] b-calendar mb-4">
                <div
                  class="w-full text-center py-7 px-5 mx-2 rounded-lg border drop-shadow-md border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary">
                  Unfortunately, we have no availability for {{ formData.guests }} guests to play
                  {{ selectedActivity.name }} at {{ selectedVenue.name }}
                </div>
              </div>
            </div>
            <div
              v-if="selectedActivityHasAvailability"
              @scroll="handleCalendarScroll"
              class="relative md:pr-5 md:py-8 md:border-2 md:border-f-bg/[.07] md:hj:border-f-tertiary md:hj:border-white/10 rounded-xl overflow-x-auto"
              ref="calendar">
              <div v-for="(times, date, j) in availabilities" :key="j" class="relative">
                <div
                  v-if="showDay(getDateTime(date).toFormat('cccc').toLowerCase())"
                  class="flex gap-2 justify-start mb-2 md:mb-8 h-[82px] b-calendar items-center relative w-max">
                  <div
                    class="max-w-[40px] min-w-[40px] md:max-w-[60px] md:min-w[60px] sticky h-full bo:bg-f-white hj:bg-black px-2 md:px-4 flex items-center justify-center left-0 top-0 z-10">
                    <div class="text-center">
                      <p class="font-medium">{{ getDateTime(date).toFormat("ccc") }}</p>
                      <p class="text-xl">
                        {{ getDateTime(date).toFormat("dd") }}
                      </p>
                      <p class="font-medium">{{ getDateTime(date).toFormat("LLL") }}</p>
                    </div>
                  </div>
                  <div class="flex-1 h-full flex pr-8">
                    <div
                      v-if="isEmpty(getTimesForActivity(times)) && showFullyBookedTimes"
                      class="w-full text-center py-7 px-5 mx-2 rounded-lg border hover:border-f-bg hover:hj:border-white cursor-pointer drop-shadow-md border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary">
                      We're fully booked
                    </div>
                    <div v-for="item in getSortedTimesForActivity(times)" :key="item.time">
                      <TimeSlot
                        :available="!!item.data.available"
                        :time="item.time"
                        :notAvailableReason="item.data.reason"
                        :selected-slot="selectedSlot"
                        :date="date"
                        :from-price="getFromPriceForTime(date, item.time)"
                        @select-slot="selectSlot(date, item.time)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="selectedActivityHasAvailability"
              class="absolute h-full top-0 right-0 w-[10%] bg-gradient-to-l bo:from-f-white hj:from-black to-transparent lg:hidden pointer-events-none"></div>
            <div
              class="absolute h-full right-0 top-0 flex items-center px-4 animate-ping lg:hidden"
              @click="scrollCalendarRight()"
              v-if="showScrollRightArrow && selectedActivityHasAvailability">
              <img :src="asset('assets/chevron-dark.svg')" alt="chevron facing right" class="rotate-90 scale-150" />
            </div>
          </div>
          <div class="flex items-center justify-between p-2 pb-32 md:pb-0 z-10 relative">
            <StyledButton mode="outlined" :disabled="disablePreviousWeekButton" @click="changeWeek('previous')">
              Previous {{ calendarNavLabel }}
            </StyledButton>
            <StyledButton mode="outlined" :disabled="disableNextWeekButton" @click="changeWeek('next')">
              Next {{ calendarNavLabel }}
            </StyledButton>
          </div>
        </div>
      </div>
      <transition
        name="slideup"
        enter-active-class="transition-transform duration-500"
        enter-from-class="transform translate-y-full"
        enter-to-class="transform translate-y-0"
        leave-active-class="transition-transform duration-500"
        leave-from-class="transform translate-y-0"
        leave-to-class="transform translate-y-full">
        <div
          v-if="canContinue"
          class="fixed left-0 bottom-0 z-50 w-full px-4 bg-f-white hj:bg-f-bg py-4 text-center border-t-2 border-t-f-bg/[.07] hj:border-t-white/10">
          <button
            type="button"
            class="bo:font-graphik-medium hj:font-druk-bold hj:text-2xl font-bold hj:font-normal rounded-full hj:rounded-xl py-3 w-full md:w-3/12 lg:w-2/12"
            :class="{
              'bg-f-contrast/30 text-f-tertiary/30 cursor-not-allowed': !canContinue,
              'bg-f-contrast text-f-tertiary hj:text-current hover:text-f-white hover:bg-f-tertiary hover:hj:bg-f-secondary hover:hj:bg-f-primary':
                canContinue,
            }"
            :disabled="!canContinue"
            @click="completeSelection()">
            Continue
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Carousel from "primevue/carousel"
import { DateTime } from "luxon"
import axios from "axios"
import VueHorizontalEs from "vue-horizontal"
import Simple from "@app/layouts/Simple.vue"
import SearchBar from "@app/components/SearchBar.vue"
import SearchCriteria from "@app/components/SearchCriteria.vue"
import LoadingSpinner from "@app/components/LoadingSpinner.vue"
import Logo from "@app/components/Logo.vue"
import { XMarkIcon } from "@heroicons/vue/24/outline"
import { isEmpty } from "lodash/lang"
import route from "ziggy"
import StyledButton from "@app/components/StyledButton.vue"
import SelectedSlot from "@app/components/SelectedSlot.vue"
import TimeSlot from "./components/TimeSlot/TimeSlot.vue"
import CalendarSkeleton from './components/CalendarSkeleton.vue'

export default {
  name: "Calendar",
  components: {
    SelectedSlot,
    SearchBar,
    SearchCriteria,
    Simple,
    Carousel,
    LoadingSpinner,
    VueHorizontalEs,
    Logo,
    XMarkIcon,
    TimeSlot,
    StyledButton,
    CalendarSkeleton,
  },
  props: {
    reservationId: {
      type: String,
      required: true,
    },
    showFullyBookedTimes: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["showOverlay", "hideOverlay", "skipSelectPackage"],
  data() {
    return {
      loading: false,
      showSearch: false,
      selectedSlot: {
        value: null,
        date: null,
        time: null,
      },
      selectedActivity: {
        id: null,
        name: null,
      },
      selectedVenue: this.$store.state.searchParams.venue,
      showSoldOutSlots: false,
      showScrollRightArrow: true,
    }
  },
  watch: {
    searchResults() {
      if (this.searchParams.activity.id === null) {
        Object.values(this.searchResults.activities).every((a) => {
          if (this.activityHasAvailability(a)) {
            this.selectActivity(a)
            this.changeActivity(this.selectedActivity)
            return false
          }
        })
      }
    },
  },
  computed: {
    sortedResultActivities() {
      return Object.values(this.searchResults.activities).sort((a, b) => a.sequence - b.sequence)
    },
    showSoldOutSlots() {
      if (window.featureFlags && window.featureFlags.showSoldOutSlots) {
        return window.featureFlags.showSoldOutSlots
      }
    },
    selectedActivityHasAvailability() {
      const urlParams = new URLSearchParams(window.location.search)
      const forceNoAvailability = urlParams.get("forceNoAvailabilityDevOnly")

      if (forceNoAvailability === "true") {
        return false
      }

      const hasAvailability = this.activityHasAvailability(this?.selectedActivity)
      return hasAvailability
    },
    DateTime() {
      return DateTime
    },
    searchResults() {
      return this.$store.state.searchResults
    },
    searchOverride() {
      return this.$store.state.searchResults?.code
    },
    weeksThatCanBeSearched() {
      return this.$store.state.searchFormData.weeks_that_can_be_searched
    },
    weeksMovedForward() {
      return this.$store.state.weeksMovedForwardsOnCalendar
    },
    availabilities() {
      return this.$store.state.searchResults.availability
    },
    dayVisibilities() {
      return this.$store.state.searchFormData.calendar_day_visibilities
    },
    searchParams() {
      return this.$store.state.searchParams
    },
    searchForm() {
      return this.$store.state.searchFormData
    },
    headingText() {
      return this.$store.state.searchFormData?.heading
    },
    subHeadingText() {
      return this.$store.state.searchFormData?.sub_heading
    },
    selectedBookingSlot() {
      return this.$store.state.selectedBookingSlot
    },
    formData() {
      return {
        reservation: this.reservationId,
        searchOverride: this.searchOverride,
        venue: this.selectedVenue.slug,
        venue_id: this.selectedVenue.id,
        activity: this.selectedActivity.slug,
        activity_id: this.selectedActivity.id,
        mode: this.searchParams.when.mode,
        when: this.searchParams.when.value,
        date: this.selectedSlot.date ?? null,
        time: this.selectedSlot.time ?? null,
        day: this.searchParams.when.day,
        month: this.searchParams.when.month,
        guests: this.searchParams.guests,
        find_alternatives: true,
      }
    },
    canContinue() {
      return this.selectedActivity.id !== null && this.selectedSlot.value !== null
    },
    disablePreviousWeekButton() {
      if (this.availabilities) {
        const currentViewDayStart = DateTime.fromISO(Object.keys(this.availabilities)[0])

        if (currentViewDayStart) {
          const isCurrentViewDayStartInThisWeek = this.daysThisWeekFromMondayToSunday().some(
            (day) => day.toISOString().split("T")[0] === currentViewDayStart.toISODate(),
          )

          if (isCurrentViewDayStartInThisWeek) {
            return true
          }
        }
      }

      if (!this.searchOverride) {
        return false
      }
      if (this.searchOverride && !this.weeksThatCanBeSearched) {
        return false
      }
      if (this.searchOverride && this.weeksMovedForward === 0) {
        return true
      }
      if (this.weeksMovedForward <= 0) {
        return true
      }
      return false
    },
    disableNextWeekButton() {
      if (!this.searchOverride) {
        return false
      }
      if (this.searchOverride && !this.weeksThatCanBeSearched) {
        return false
      }
      if (
        this.searchOverride &&
        (this.weeksThatCanBeSearched <= 1 || this.weeksThatCanBeSearched === this.weeksMovedForward)
      ) {
        return true
      }
      return false
    },
    calendarNavLabel() {
      if (this.$store.state.searchParams.when.mode === "date") {
        return "week"
      } else {
        return this.$store.state.searchParams.when.day === "weekend" ? "weekend" : "week"
      }
    },
    weekStart() {
      return DateTime.fromISO(Object.keys(this.availabilities)[0])
    },
    trackInteractProperties() {
      return {
        event: "calendar",
        properties: {
          location: window.location.href,
          venue: this.selectedVenue.name,
          date: this.selectedSlot.date,
          time: this.selectedSlot.time,
          activity: this.selectedActivity.name,
        },
        trackingServices: this.trackingGroups.all,
      }
    },
  },
  methods: {
    daysThisWeekFromMondayToSunday() {
      const today = new Date()
      const dayOfWeek = today.getDay() // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)

      const monday = new Date(today)
      const sunday = new Date(today)

      // Calculate the date for Monday of the current week
      monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1))

      // Calculate the date for Sunday of the current week
      sunday.setDate(monday.getDate() + 6)

      const daysOfWeek = []
      for (let date = new Date(monday); date <= sunday; date.setDate(date.getDate() + 1)) {
        daysOfWeek.push(new Date(date)) // Push a new date object to avoid reference issues
      }

      return daysOfWeek
    },
    isEmpty, // Import from lodash, it's required here
    handleCalendarScroll: function (el) {
      const srcElement = el.srcElement
      const srcElementWidth = srcElement.scrollWidth
      // If not finished scrolling
      if (srcElement.scrollLeft + srcElement.clientWidth < srcElementWidth) {
        this.showScrollRightArrow = true // Show the arrow while scrolling is possible
      } else {
        this.showScrollRightArrow = false // Hide the arrow when the end is reached
      }
    },
    scrollCalendarRight() {
      this.$refs.calendar.scrollBy({
        left: 100,
        behavior: "smooth",
      })
    },
    checkFeatureFlags() {
      if (window.featureFlags && window.featureFlags.showSoldOutSlots) {
        this.showSoldOutSlots = window.featureFlags.showSoldOutSlots
        return true
      }
    },
    activityHasAvailability(activity) {
      let hasAvailability = false
      const availabilities = Object.values(this.availabilities)
      for (const times of availabilities) {
        const timeValues = Object.values(times)
        for (const time of timeValues) {
          if (activity.id in time.activities) {
            hasAvailability = true
            break
          }
        }
        if (hasAvailability) {
          break
        }
      }
      return hasAvailability
    },
    showSearchBar() {
      this.$emit("showOverlay")
      this.$store.dispatch("updatePreviousSearchParams", this.$store.state.searchParams)
      this.showSearch = true
    },
    closeSearchBar() {
      this.showSearch = false
      if (this.$store.state.searchChanged) {
        this.$store.dispatch("updateSearchParams", this.$store.state.previousSearchParams)
      }
      this.updateSearchChanged(false)
      this.$emit("hideOverlay")
    },
    searchFinished() {
      this.showSearch = false
      this.$emit("hideOverlay")
    },
    getSortedTimesForActivity(times) {
      times = this.getTimesForActivity(times)

      // Convert the object to an array of entries and sort it
      const sortedTimes = Object.entries(times)
        .sort(([timeA], [timeB]) => {
          // Special case for "00:00" to place it at the end
          if (timeA === "00:00") return 1
          if (timeB === "00:00") return -1

          // Split the times into hours and minutes for comparison
          const [hoursA, minutesA] = timeA.split(":").map(Number)
          const [hoursB, minutesB] = timeB.split(":").map(Number)

          // Compare hours first, then minutes
          return hoursA - hoursB || minutesA - minutesB
        })
        .map(([time, data]) => ({ time, data })) // Convert back to array of objects

      return sortedTimes
    },
    getTimesForActivity(times) {
      let results = {}
      Object.keys(times).forEach((key) => {
        const entry = times[key]

        if (entry.available && entry.activities.hasOwnProperty(this.selectedActivity.id)) {
          results[key] = entry
        }

        if (!this.showSoldOutSlots) return
        if (!entry.available && entry.reason === "sold_out") {
          results[key] = entry
        }
      })
      return results
    },
    showDay(day) {
      return this.dayVisibilities.find((d) => d.day === day).visible
    },
    scrollToSelectedActivity() {
      if (this.isMobile) {
        this.$refs?.activities?.scrollToIndex(
          this.venueActivities.find((v) => v.source_id === this.selectedActivity.source_id).id - 1,
        )
      }
    },
    selectSlot(date, time) {
      const selectedDateTime = DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm").toFormat("yyyy-MM-dd HH:mm")

      if (this.selectedSlot.value === selectedDateTime) {
        // If the same slot is tapped again, clear the selection
        this.selectedSlot.value = null
        this.selectedSlot.date = null
        this.selectedSlot.time = null
        this.$store.dispatch("updateSelectedBookingSlot", {
          date: null,
          time: null,
          activity: null,
          venue: null,
          guests: null,
        })
      } else {
        // If a new slot is selected, proceed as usual
        const displayDate = DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm").toFormat("EEE, dd MMM - HH:mm")
        this.selectedSlot.value = selectedDateTime
        this.selectedSlot.date = date
        this.selectedSlot.time = time
        this.$store.dispatch("updateSelectedBookingSlot", {
          date: DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("EEE, dd MMM yyyy"),
          time: time,
          activity: this.selectedActivity,
          venue: this.selectedVenue,
          guests: this.searchParams.guests,
        })

        // const params = this.searchParams
        // params.when.value = this.selectedSlot.value
        // params.when.display = DateTime.fromFormat(this.selectedSlot.value, 'yyyy-MM-dd HH:mm').toFormat('EEE, dd MMM - HH:mm')
        // this.updateSearchParams(params)

        this.trackInteract(
          this.trackInteractProperties.event,
          this.trackInteractProperties.properties,
          this.trackingGroups.all,
        )
      }
    },

    selectActivity(activity) {
      this.selectedActivity = activity
    },
    changeWeek(direction) {
      if (direction === "next") {
        this.$store.dispatch("updateWeeksMovedForwardOnCalendar", { type: "add" })
      }
      if (direction === "previous") {
        this.$store.dispatch("updateWeeksMovedForwardOnCalendar", { type: "subtract" })
      }
      this.loading = true
      let weekStart = null

      const params = this.searchParams
      //params.when.value = null
      //params.when.display = 'Any Time'
      this.selectedSlot.value = null
      this.selectedSlot.date = null
      this.selectedSlot.time = null
      this.updateSearchParams(params)

      let fields = this.formData
      fields.when = null
      fields.date = null
      fields.time = null

      if (direction === "previous") {
        weekStart = this.weekStart.minus({ weeks: 1 }).startOf("week").toFormat("yyyy-MM-dd")
      }
      if (direction === "next") {
        weekStart = this.weekStart.plus({ weeks: 1 }).startOf("week").toFormat("yyyy-MM-dd")
      }
      fields.week_start = weekStart

      this.$inertia.get(route(this.getRoute("reservation.search"), fields))
      // axios.post(route(this.getRoute('bookings.search'), this.reservationId), fields)
      //     .then(response => {
      //       this.$store.dispatch('updateSearchResults', response.data)
      //       this.loading = false
      //     })
      //     .catch(error => {
      //     })
    },
    changeActivity(activity) {
      this.loading = true
      this.selectedActivity = activity

      const params = this.searchParams
      params.activity.id = activity.id
      params.activity.name = activity.name
      if (params.when.mode !== "flexible") {
        //params.when.value = null
        //params.when.display = 'Any Time'
      }
      this.updateSearchParams(params)

      if (Object.keys(this.searchResults.activities).length > 1 || this.searchForm.activities.length <= 1) {
        setTimeout(() => {
          this.loading = false
        }, 1200)
      }

      // We need to do an ajax call, as we don't have details on the other activities here
      if (Object.keys(this.searchResults.activities).length <= 1 && this.searchForm.activities.length > 1) {
        const data = this.formData
        //data.when = null
        //data.date = null
        //data.time = null
        axios
          .post(route(this.getRoute("bookings.search"), this.reservationId), data)
          .then((response) => {
            this.$store.dispatch("updateSearchResults", response.data)
            this.loading = false
          })
          .catch((error) => {})
      }
    },
    completeSelection() {
      axios.post(route(this.getRoute("pending_change.update"), this.reservationId), this.formData).then((r) => {
        if (r.data.success) {
          this.trackComplete("calendar", this.trackInteractProperties.properties, this.trackingGroups.all)
          this.$store.dispatch("updateSearchNextStep", "select_package")
          if (this.$store.state.searchNextPage) {
            this.$emit("skipSelectPackage")
          }
        }
      })
    },
    updateSearchParams(params) {
      this.$store.dispatch("updateSearchParams", params)
      this.$store.dispatch("updateSearchParamsForCurrentSearch", params)
    },
    updateSearchChanged(changed) {
      this.$store.dispatch("updateSearchChanged", changed)
    },
    getFromPriceForTime(date, time) {
      const packages = this.searchResults.availability[date][time].packages
      const price = Math.min(...Object.values(packages))
      return Object.keys(packages).length > 1 ? `from £${price}pp` : `£${price}pp`
    },
    calculateWeeksMovedFowardsOnCalendar() {
      if (!this.searchOverride) {
        return 0
      } else {
        return Math.ceil(this.weekStart.diff(DateTime.fromISO(this.searchForm.from_date), "weeks").weeks)
      }
    },
  },
  mounted() {
    this.$store.dispatch("updateWeeksMovedForwardOnCalendar", {
      type: "set",
      value: this.calculateWeeksMovedFowardsOnCalendar(),
    })
    this.selectActivity(this.searchParams.activity.id ? this.searchParams.activity : this.searchForm.activities[0])
    this.changeActivity(this.selectedActivity)
    this.scrollToSelectedActivity()
    this.trackView(
      "calendar",
      {
        location: window.location.href,
      },
      this.trackingGroups.all,
    )

    if (!this.selectedActivityHasAvailability) {
      this.trackNoAvailabilityFound(
        {
          location: window.location.href,
          activity_name: this.searchParams.activity?.name,
          guests: this.searchParams.guests,
          date: this.searchParams.when.display,
        },
        this.trackingGroups.all,
      )
    }

    const self = this

    window.featureFlags = {
      showSoldOutSlots: window?.featureFlags?.showSoldOutSlots ?? false,
    }

    window.featureFlags = new Proxy(window.featureFlags, {
      set(target, property, value) {
        if (property === "showSoldOutSlots" && target[property] !== value) {
          target[property] = value
          self.checkFeatureFlags()
          return true
        }
        target[property] = value
        return true
      },
    })

    this.checkFeatureFlags()
  },
  created() {},
}
</script>

<style scoped lang="scss">
.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.3s ease-in;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
