<script setup>
import { computed } from "vue"

const props = defineProps({
  time: {
    type: String,
    required: true,
  },
  reason: {
    type: String,
    required: true,
  },
})

const displayReason = computed(() => {
  let reason = props.reason

  switch (reason) {
    case "sold_out":
      return "Sold Out"
    default:
      return "Unavailable"
  }
})
</script>

<template>
  <div
    class="w-[150px] text-center py-7 px-5 mx-2 rounded-lg border hover:border-f-tertiary hover:hj:border-white cursor-pointer drop-shadow-md border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary opacity-50">
    {{ time }}<br />
    <span class="text-f-white bo:text-f-tertiary font-bold">
      {{ displayReason }}
    </span>
  </div>
</template>
