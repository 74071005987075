<template>
  <div>
    <div class="w-9/12 mx-auto mt-28 text-center">
      <h1 class="bo:font-graphik-bold hj:font-druk-heavy-italic text-3xl text-center mb-4">No availability found</h1>
      <p class="bo:font-graphik-medium hj:font-druk-bold text-center mb-8">
        Sorry we couldn't find any times available for your requested date and activity.
      </p>
      <button
        class="w-full md:w-auto rounded-xl bg-f-contrast py-3 md:py-3 px-6 text-f-white cursor-pointer hover:bg-f-tertiary hover:hj:bg-f-secondary mx-auto"
        @click="reload()">
        Try another search
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResults",
  methods: {
    reload() {
      window.location.reload()
    },
  },
}
</script>

<style scoped></style>
