<template>
  <div
    class="py-2 px-4 inline-flex items-center cursor-pointer justify-center bg-f-tertiary/[.07] hj:bg-f-tertiary rounded-xl border-2 border-f-bg/[.07] hj:border-white/10 mt-10 mx-auto">
    <template v-if="fields.venue">
      <p class="text-sm md:text-base">{{ searchParams.venue?.name }}</p>
      <p class="px-1.5 md:px-3">|</p>
    </template>
    <template v-if="fields.guests">
      <p class="text-sm md:text-base">{{ searchParams.guests ?? 1 }} Guests</p>
      <p class="px-1.5 md:px-3">|</p>
    </template>
    <template v-if="fields.when">
      <p class="text-sm md:text-base">{{ date }}</p>
    </template>
    <template v-if="fields.activity">
      <p class="px-1.5 md:px-3">|</p>
      <p class="text-sm md:text-base">{{ searchParams.activity?.name }}</p>
    </template>
    <img :src="asset('assets/magnifying-glass-dark.svg')" alt="User icon" class="w-4 ml-3" />
  </div>
</template>

<script>
export default {
  name: "SearchCriteria",
  props: {
    venues: {
      type: Array,
      required: true,
    },
    activities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        venue: true,
        guests: true,
        when: true,
        activity: true,
      },
    }
  },
  mounted() {
    this.setFieldVisibility()
  },
  computed: {
    searchParams() {
      return this.$store.state.searchParams
    },
    date() {
      if (this.searchParams.when.display) {
        return this.searchParams.when.display
      } else {
        return "Any time"
      }
    },
  },
  methods: {
    setFieldVisibility() {
      if (this.venues.length <= 1) {
        this.fields.venue = false
      }

      if (this.activities.length <= 1) {
        this.fields.activity = false
      }
    },
  },
}
</script>
