<template>
  <div
    class="py-2 px-4 inline-flex items-center justify-center rounded-xl border bg-f-contrast border-f-bg hj:border-white b-drop-shadow-light">
    <template v-if="fields.venue">
      <p class="text-sm md:text-base">{{ selectedBookingSlot.venue.name }}</p>
      <p class="px-1.5 md:px-3">|</p>
    </template>
    <template v-if="fields.guests">
      <p class="text-sm md:text-base">{{ selectedBookingSlot.guests }} Guests</p>
      <p class="px-1.5 md:px-3">|</p>
    </template>
    <template v-if="fields.when">
      <p class="text-sm md:text-base">{{ selectedBookingSlot.date }} - {{ selectedBookingSlot.time }}</p>
    </template>
    <template v-if="fields.activity">
      <p class="px-1.5 md:px-3">|</p>
      <p class="text-sm md:text-base">{{ selectedBookingSlot.activity.name }}</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "SelectedSlot",
  props: {
    venues: {
      type: Array,
      required: true,
    },
    activities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        venue: true,
        guests: true,
        when: true,
        activity: true,
      },
    }
  },
  computed: {
    selectedBookingSlot() {
      return this.$store.state.selectedBookingSlot
    },
  },
  methods: {
    setFieldVisibility() {
      if (this.venues.length <= 1) {
        this.fields.venue = false
      }

      if (this.activities.length <= 1) {
        this.fields.activity = false
      }
    },
  },
  mounted() {
    this.setFieldVisibility()
  },
}
</script>

<style scoped></style>
